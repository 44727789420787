<template>
	<div class="container" v-loading='loading'>
		<!-- <div class="top" style="background: #fff;padding: 15px;">
			<div style="display: flex;justify-content: space-between;align-items: center;padding-bottom: 15px;border-bottom: 1px solid #ddd;">
				<span>关联爱铺货账户设置</span>
				<el-link type="primary" href='https://www.yuque.com/docs/share/e2798d00-167c-4dea-8afa-a4c06b8cd53c?#' target="_blank">一键铺货教程</el-link>
			</div>
			<div style="display: flex;align-items: center;">
				<span>appkey:{{appkey}}</span>
				<span style="margin: 0 50px 0 250px;">Appsecret:{{Appsecret}}</span>
				<el-button type="text" @click='Setkey'>设置</el-button>
			</div>
			<div style="color: #999;margin-top:10px;font-size: 14px;">请设置你爱铺货账户的Appkey与Appsecret，Appkey与Appsecret获取请参考一键铺货教程。</div>
		</div> -->

		<div style="background: #fff;padding: 15px;margin-top: 15px;">
			<div class="filter-container">
				<div class="filter-item">
					<label class="label">关键字: </label>
					<el-input v-model="searchKey" @keyup.enter.native="goodsFilter" placeholder="商品名称、编码、规格条形码" style="width: 200px;;margin-right: 10px;"
					 clearable></el-input>
				</div>
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsFilter">查询</el-button>
				<!-- <div class="filter-item">
					<label class="label">铺货状态: </label>
					<el-select v-model="lineState" style="width: 160px;margin-right: 10px;">
						<el-option v-for="item in lineSelect" :key="item.id" :label="item.value" :value="item.id">
						</el-option>
					</el-select>
				</div> -->
			</div>
			<div style="font-size: 14px;">
				<div>1.若未找到您期望的商品，您还可通过爱铺货添加，<el-link type="primary" href='https://www.yuque.com/docs/share/e2798d00-167c-4dea-8afa-a4c06b8cd53c?#' target="_blank">如何通过爱铺货添加</el-link></div>
				<div style="margin-top: 10px;">2.通过一键铺货，可以将天猫、淘宝、京东等平台的商品详情信息添加到商城中</div>
			</div>
			<!-- <div class="filter-container">
				<div class="filter-item">
					<label class="label">创建时间: </label>
					<el-date-picker v-model="TimeValue" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
					 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00','23:59:59']">
					</el-date-picker>
					<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsFilter">查询</el-button>
					<el-button style="margin-left:30px;width:120px;" @click="getProductlist">获取商品</el-button>
				</div>
			</div> -->
		</div>
		<div class="table-container">
			<el-table :data="groupData" style="width:100%;" v-loading="loading" ref="multipleTable" @selection-change="handleSelectionChange"
			 @sort-change="sortChange" empty-text="查询不到相关商品">
				<el-table-column label="商品" width="450">
					<template slot-scope="scope">
						<div class="product-info" style="align-items:flex-start">
							<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl+'@!cut64'">
							<svg-icon v-else icon-class="noImgIcon" />
							<div style="min-height: auto;">
								<div style="display:flex;justify-content:space-between;align-items:flex-start;">
									<div style="width:280px;">
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
									</div>
								</div>
								<div style="color:#909399">{{scope.row.ProductNo}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="ProductPrice" label="价格" sortable>
					<template slot-scope="scope">
						<div style="display:flex;justify-content:flex-start;align-items:center;">
							<div style="white-space: nowrap;margin-right: 5px;color:#F56C6C;">￥{{scope.row.ProductPrice}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="Stock" label="库存" sortable>
					<template slot-scope="scope">
						<div style="display:flex;justify-content:flex-start;align-items:center;">
							<div style="white-space: nowrap;margin-right: 5px;">{{scope.row.Stock}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="240">
					<template slot-scope="scope">
						<el-button type="text" @click="eidtGroup(scope.row)" class="pointers">查看</el-button>
						<el-button type="text" @click='shipChange(scope.row)'>
							<span style="color:#409EFF;">上架</span>
						</el-button>
						<!-- <el-button type="text" @click="deleteChose(scope.row)" style="color:#F56C6C;" class="pointers">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			
			<div style="display:flex;justify-content:flex-end;align-items:center;background: #fff;padding-bottom: 15px;">
				<!-- <div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin:20px 0 0 13px;">
					<el-checkbox @change="tabCheckedChange" v-model="checked">当前页全选</el-checkbox>
					<el-button size="mini" style="margin-left: 20px;" @click="deleteChose({})">批量删除</el-button>
					<el-button size="mini" style="margin-left: 20px;" @click="shipChange({})" v-if='lineState == 0'>批量上架</el-button>
				</div> -->
				
				<el-pagination v-if="Total" style="margin:20px 0;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
				
			</div>
		</div>

		<el-dialog :visible.sync="keyVisable" width="50%" title="设置Appkey和Appsecret">
			<div class="editKey" style="margin-bottom: 20px;">
				<span style="width: 100px;text-align: right;margin-right: 10px;">Appkey:</span>
				<el-input v-model="appkey" style="width: 300px;" placeholder='请输入Appkey'></el-input>
			</div>
			<div class="editKey">
				<span style="width: 100px;text-align: right;margin-right: 10px;">Appsecret:</span>
				<el-input v-model="Appsecret" style="width: 300px;" placeholder='请输入Appsecret'></el-input>
			</div>
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="keyVisable=false">取消</el-button>
				<el-button type="primary" style="width: 120px;" @click="saveChange" :loading="loading">确定</el-button>
			</div>
		</el-dialog>

		<!-- 批量修改配送方式 -->
		<el-dialog title="设置配送方式" :visible.sync="shipSendVisible" width="1200px">
			<el-form :model="shipSendForm" :rules="rules" ref="shipSendForm" label-width="150px" class="demo-ruleForm">
				<div style="width:100px;text-align: right;float: left;height:36px;line-height: 36px;"><span class="tablered">*</span>配送方式</div>
				<div style="margin-left:120px">
					<div>
						<el-form-item label-width='0' :prop="(shipSendForm.IsOpenExpress||shipSendForm.IsOpenSelfExtract)?'nocheck':'IsOpenExpress'">
							<el-checkbox v-model="shipSendForm.IsOpenExpress">快递发货</el-checkbox>
							<el-checkbox v-model="shipSendForm.IsOpenSelfExtract">到店自提</el-checkbox>
						</el-form-item>
					</div>
					<el-radio-group v-model="shipSendForm.FreightType" v-if="shipSendForm.IsOpenExpress">
						<el-form-item label-width='0' :prop="shipSendForm.FreightType==0?'UniformFreight':'nocheck'">
							<el-radio :label="0">全国统一运费
								<el-input v-model="shipSendForm.UniformFreight" style="width:200px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
								元
							</el-radio>
						</el-form-item>

						<el-form-item label-width='0' :prop="shipSendForm.FreightType==1?'FreightTemplateId':'nocheck'">
							<el-radio :label="1" style="margin-top:10px">使用运费模板
								<el-select v-model="shipSendForm.FreightTemplateId" placeholder="请选择运费模板" @change="frightChange" filterable
								 clearable>
									<el-option v-for="(item,index) in FreightTemplateIdList " :key="item.Id" :value="item.Id" :label="item.TemplateName"></el-option>
								</el-select>
							</el-radio>
						</el-form-item>
					</el-radio-group>
					<div style="margin:15px 0; width:1000px" v-if="(shipSendForm.FreightTemplateId&&frightData.length)&&(shipSendForm.IsOpenExpress)">
						<div style="margin-bottom: 15px;">{{TemplateName}}</div>
						<el-table :data="frightData" style="width: 100%" border>
							<el-table-column prop="AreaNames" label="配送地区" width="250" key='1'>
								<template slot-scope="scope">
									<el-tag :key="tag" v-for="(tag,index1) in scope.row.AreaNames" style="margin:0 10px 10px 10px">
										{{tag}}
									</el-tag>
								</template>
							</el-table-column>
							<el-table-column :label="FreightTemplateType == 1 ? '首重(KG)' : '首件(个)'" key='2'>
								<template slot-scope="scope">
									<div>{{FreightTemplateType == 1? scope.row.FirstWeightToNumber : scope.row.FirstCountToNumber}}</div>
								</template>
							</el-table-column>

							<el-table-column label="运费(元)" key='3'>
								<template slot-scope="scope">
									<div>{{FreightTemplateType == 1? scope.row.FirstWeightMoney : scope.row.FirstMoneyToNumber}}</div>
								</template>
							</el-table-column>

							<el-table-column :label="FreightTemplateType == 1 ? '续重(KG)' : '续件(个)'" key='4'>
								<template slot-scope="scope">
									<div>{{FreightTemplateType == 1? scope.row.ContinueWeightToNumber : scope.row.ContinueCountToNumber}}</div>
								</template>
							</el-table-column>
							<el-table-column label="续费(元)" key='5'>
								<template slot-scope="scope">
									<div>{{FreightTemplateType == 1? scope.row.ContinueWeightMoney : scope.row.ContinueMoneyToNumber}}</div>
								</template>
							</el-table-column>
						</el-table>
						<div class="nofrightData" v-if="nofrightData.length" style="width: 1000px;margin-top: 10px;">
							<div style="background:#F5F5F5;color:#909399;border: 1px solid #EBEEF5;height: 44px;line-height: 44px;padding-left: 10px;font-weight: bold;">不配送地区</div>
							<div style="display: flex;border: 1px solid #EBEEF5;">
								<el-tag :key="tag" v-for="(tag,index1) in nofrightData" style="margin:10px">
									{{tag}}
								</el-tag>
							</div>
						</div>
					</div>
				</div>
				<div style="text-align: center;margin: 40px 0 20px;">
					<el-button type="primary" @click="saveShipinfo('shipSendForm',1)" style="width:120px" :loading="ShipLoading1">保存到仓库</el-button>
					<el-button type="primary" @click="saveShipinfo('shipSendForm',2)" style="width:120px" :loading="ShipLoading2">保存并上架</el-button>
				</div>
			</el-form>

		</el-dialog>
	</div>
</template>

<script>
	import {
		freightTemplateinfo,
		freightTemplateList
	} from '@/api/goods'
	import {
		ipuhuoInit,
		ipuhuoSavesetting,
		ipuhuoIndexlist,
		ipuhuogetItem,
		ipuhuosavetoproduct,
		ipuhuodelete,
		ipuhuodeleteBatch,
		ipuhuosavetoproductBatch
	} from '@/api/TurnTomySelf'
	import config from '@/config/index'
	export default {
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var checkIsOpenExpress = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('商品配送方式至少选择一种'));
				} else {
					return callback();
				}

			};
			var checkUniformFreight = (rule, value, callback) => {
				if ((0 < value && value <= 100000000) || value === 0 || value === '0') {
					return callback();
				} else if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback(new Error('请设置商品运费'));
				}
			};
			var checkFreightTemplateId = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请选择运费模板'));
				} else {
					return callback();
				}
			};
			return {
				recordProduct: {},
				imgUrl: config.IMG_BASE,
				OrderBy: '',
				IsAsc: false,
				FreightTemplateType: null,
				nofrightData: [],
				shipSendIds: [],
				ShipLoading1: false,
				ShipLoading2: false,
				shipSendForm: {
					IsOpenExpress: false,
					IsOpenSelfExtract: false,
					FreightType: 0,
					FreightTemplateId: null,
				},
				frightData: [],
				TemplateName: '',
				FreightTemplateIdList: [],
				shipSendVisible: false,
				rules: {
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					//是否打开配送方式
					IsOpenExpress: [{
						validator: checkIsOpenExpress,
						trigger: 'change'
					}],
					//全国统一运费
					UniformFreight: [{
						validator: checkUniformFreight,
						trigger: 'change'
					}],
					//是否选择运费模板
					FreightTemplateId: [{
						validator: checkFreightTemplateId,
						trigger: 'change'
					}]
				},
				//结束
				loading: false,
				appkey: '',
				Appsecret: '',
				keyVisable: false,
				searchKey: '',
				lineState: 0,
				lineSelect: [{
					value: '待铺货',
					id: 0
				}, {
					value: '已铺货',
					id: 1
				}],
				currentPage: 1,
				pageSize: 20,
				Total: 3,
				groupData: [],
				TimeValue: [],
				multipleSelection: [],
				isCurrentAll: false,
				checked: false,
				goUrls: config.GO_URL,
			}
		},
		created() {
			this.getInfor()
			this.getInitinfo()
			this.getList()
		},
		methods: {
			async getInitinfo() {
				try {
					this.loading = true
					let result = await ipuhuoInit()
					if (result.IsSuccess) {
						this.appkey = result.Result.AppKey
						this.Appsecret = result.Result.AppSecret
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			//筛选数据
			async getInfor() {
				try {
					// 获取运费模板数据
					let data = {
						OrderBy: 'AddTime',
						IsAsc: false
					}
					let result3 = await freightTemplateList(data)
					this.FreightTemplateIdList = result3.Result
				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			//保存配送方式
			saveShipinfo(formName, index) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							if (index == 1) {
								this.ShipLoading1 = true
							} else {
								this.ShipLoading2 = true
							}
							//单个保存
							if (this.recordProduct.Id) {
								let data = {
									Id: this.recordProduct.Id,
									IsOpenExpress: this.shipSendForm.IsOpenExpress,
									IsOpenSelfExtract: this.shipSendForm.IsOpenSelfExtract,
									FreightType: this.shipSendForm.FreightType,
									UniformFreight: this.shipSendForm.UniformFreight,
									FreightTemplateId: this.shipSendForm.FreightTemplateId
								}
								if (index == 1) {
									data.IsOpen = false
								} else {
									data.IsOpen = true
								}
								let result = await ipuhuosavetoproduct(data)
								if (result.IsSuccess) {
									this.$message({
										showClose: true,
										type: 'success',
										message: '操作成功!'
									});
									this.getList()
									this.shipSendVisible = false;
								}
								else{
									this.$message({
										showClose: true,
										type: 'error',
										message:result.Message
									});
								}
							}
							//多个保存
							else {
								let data = {
									IdList: this.shipSendIds,
									IsOpenExpress: this.shipSendForm.IsOpenExpress,
									IsOpenSelfExtract: this.shipSendForm.IsOpenSelfExtract,
									FreightType: this.shipSendForm.FreightType,
									UniformFreight: this.shipSendForm.UniformFreight,
									FreightTemplateId: this.shipSendForm.FreightTemplateId
								}
								if (index == 1) {
									data.IsOpen = false
								} else {
									data.IsOpen = true
								}
								let result = await ipuhuosavetoproductBatch(data)
								if (result.IsSuccess) {
									this.$message({
										showClose: true,
										type: 'success',
										message: '操作成功!'
									});
									this.getList()
									this.shipSendVisible = false;
								}
							}
						} catch (e) {
						} finally {
							if (index == 1) {
								this.ShipLoading1 = false
							} else {
								this.ShipLoading2 = false
							}
						}
					} else {
						return false;
					}
				});
			},
			//选择运费模板
			frightChange() {
				if (this.shipSendForm.FreightTemplateId) {
					this.TemplateName = ''
					this.FreightTemplateIdList.map(item => {
						if (item.Id == this.shipSendForm.FreightTemplateId) {
							this.TemplateName = item.TemplateName
						}
					})

				}
				this.getTemplate();
			},
			// 获取运费模板
			async getTemplate() {
				try {
					if (this.shipSendForm.FreightTemplateId) {
						let data1 = {
							Id: this.shipSendForm.FreightTemplateId
						}
						this.frightData = [];
						this.nofrightData = []
						let result = await freightTemplateinfo(data1)
						this.FreightTemplateType = result.Result.FreightTemplateType
						this.frightData = result.Result.DetailList;
						this.nofrightData = result.Result.NonDistributionAreaNames
					}

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			//批量铺货
			shipChange(record) {
				if (record.Id) {
					this.recordProduct = record
				} else {
					this.recordProduct = {}
					if (this.multipleSelection.length < 1) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请选择商品!'
						});
						return
					}
					this.shipSendIds = []
					this.multipleSelection.map(record => {
						this.shipSendIds.push(record.Id)
					})
				}
				this.shipSendVisible = true
				this.shipSendForm.IsOpenExpress = false
				this.shipSendForm.IsOpenSelfExtract = false
				this.shipSendForm.FreightType = 0
				this.shipSendForm.FreightTemplateId = null

				//配送方式ids
				this.$nextTick(() => {
					this.$refs.shipSendForm.clearValidate()
				})
			},
			tabCheckedChange() {
				this.$refs['multipleTable'].toggleAllSelection();
			},
			//删除
			deleteChose(record) {
				if (record.Id) {
					this.recordProduct = record
					this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {
						try {
							let result = await ipuhuodelete({
								Id: record.Id
							})
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '删除成功!'
								});

							}
						} catch (err) {

						} finally {

						}

					}).catch(() => {
						this.$message({
							showClose: true,
							type: 'info',
							message: '已取消删除'
						});
					}).finally(() => {
						this.currentPage = 1;
						setTimeout(() => {
							this.goodsFilter();
						}, 500)

					})
				} else {
					if (this.multipleSelection.length < 1) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请选择商品!'
						});
						return
					}
					let deleteids = this.multipleSelection.map(item => {
						return item.Id
					})
					this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {
						try {
							let result = await ipuhuodeleteBatch({
								IdList: deleteids
							})
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '删除成功!'
								});
							}
						} catch (err) {

						} finally {

						}

					}).catch(() => {
						this.$message({
							showClose: true,
							type: 'info',
							message: '已取消删除'
						});
					}).finally(() => {
						this.currentPage = 1;
						setTimeout(() => {
							this.goodsFilter();
						}, 500)

					})

				}

			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			//编辑
			eidtGroup(item) {
				let productId = item.Id ? item.Id : 0
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/goods/creatGoodDetailsAgain?Id=' + productId
				window.open(url)
			},
			// 排序
			sortChange(column, prop, order) {
				this.OrderBy = column.prop;
				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
				} else {
					this.IsAsc = false
				}
				this.currentPage = 1;
				this.getList();
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.isCurrentAll = val.length == this.groupData.length ? true : false
				if (this.isCurrentAll) {
					this.checked = true
				} else {
					this.checked = false
				}
			},
			//获取商品
			async getProductlist() {
				try {
					this.loading = true
					let result = await ipuhuogetItem()
					if (result.IsSuccess) {
						this.getList()
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			//设置key
			Setkey() {
				this.keyVisable = true
			},
			// 搜索
			goodsFilter() {
				this.currentPage = 1;
				this.getList();
			},
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey,
						State: this.lineState,
						StartTime: this.TimeValue == null ? '' : this.TimeValue[0],
						EndTime: this.TimeValue == null ? '' : this.TimeValue[1],
						OrderBy: this.OrderBy,
						IsAsc: this.IsAsc,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await ipuhuoIndexlist(data)
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			async saveChange() {
				try {
					if (!this.appkey) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入Appkey'
						});
						return
					}
					if (!this.Appsecret) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入Appsecret'
						});
						return
					}
					this.loading = true
					let data = {
						AppKey: this.appkey,
						AppSecret: this.Appsecret
					}
					let result = await ipuhuoSavesetting(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						});
						this.keyVisable = false
						this.getList()
					}
				} catch (err) {

				} finally {
					this.loading = false
				}

			}
		}
	}
</script>

<style lang="less" scoped>
	.container {
		.editKey {
			display: flex;
			align-items: center;
		}

		.tablered {
			color: red;
			vertical-align: -3px;
			margin-right: 5px;
		}
	}
</style>
